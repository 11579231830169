import React from 'react'
import gatsboy from '../../assets/images/gatsboy_logo.png'
if (typeof window !== `undefined`) {
       console.log("%c Built & Managed     \n by GATSBOY          \n https://gatsboy.com ", "background: #062484; color: #fff; font-size: normal; padding: 10px;");
   }


const Footer = (props) => (
    <footer id="footer">
        <div className="container">
            <ul className="copyright col-md-9">
                <li>&copy; Σπιτικό Παπακωνσταντίνου "mpares.com" {new Date().getFullYear()}</li>
            </ul>
            <div className="copyright col-md-3 text-right">
                <span className="Gatsboy">Built and managed by <a href="https://gatsboy.com"><img className="gLogo" src={gatsboy} alt="Gatsboy"></img></a></span>
            </div>
        </div>
    </footer>
)

export default Footer
