import React from 'react'
import { Container } from 'reactstrap'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import Footer from '../layout/Footer'
import { StaticQuery, graphql } from 'gatsby'
import Sidebar from './sidebar'

// code syntax-highlighting theme
// feel free to change it to another one
import 'prismjs/themes/prism-twilight.css'

// main site style
import './index.scss'

// images
import logo from "../../assets/images/logo.png";
import logo_san from "../../assets/images/logo_san.png";
import { slide as Menu } from 'react-burger-menu'

class MobileMenu extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }

  render () {
    return (
      <Menu right className="mobile-menu">
                <Link to='/' className='bm-item'>ΑΡΧΙΚΗ</Link>
                <Link to='/χειροποιητες-μπαρες-δημητριακων' className='bm-item'>ΜΠΑΡΕΣ</Link>
                <Link to='/συσκευασμενα-κρυα-σαντουιτς' className='bm-item'>ΣΑΝΤΟΥΙΤΣ</Link>
                <Link to='/ta-nea-mas' className='bm-item'>ΝΕΑ</Link>
                <Link to='/to-spitiko' className='bm-item'>Η ΕΤΑΙΡΕΙΑ</Link>
                <Link to='/epikoinonia' className='bm-item'>ΕΠΙΚΟΙΝΩΝΙΑ</Link>
                <Link to='/about-us' className='bm-item'>EN</Link>
      </Menu>
    );
  }
}


const TemplateWrapper = ({ children, data }) => {
  let user
  if (typeof window !== 'undefined') {
    user = window.netlifyIdentity && window.netlifyIdentity.currentUser()
  }
  return (
    <StaticQuery query={pageQuery} render={data => (
      <div className='App'>
        <Helmet 
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Χειροποιητες Μπαρες Δημητριακών' },
            { name: 'keywords', content: 'μπαρες δημητριακών, μπάρες ενέργειας, κρύα σάντουιτς, δημητριακά, μπάρες, το σπιτικό, σπιτικό, σπιτικο παπακωνσταντίνου' },
            { name: 'google-site-verification', content: 'kYU3K2NvvPT41E_WonPPPLG8fVe9NBgLHV7hFJZmznw'}
          ]}>
          
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.mpares.com/" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:description" content="Χειροποιητες Μπαρες Δημητριακών" />
          <meta property="og:image" content="/static/logo-5745133007aa03f40c95628845e99934.png" />

          
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://www.mpares.com/" />
          <meta property="twitter:title" content={data.site.siteMetadata.title} />
          <meta property="twitter:description" content="Χειροποιητες Μπαρες Δημητριακών" />
          <meta property="twitter:image" content="/static/logo-5745133007aa03f40c95628845e99934.png" />
          </Helmet>
          <MobileMenu/>
        <div className='navbar navbar-expand-lg navbar-dark bg-white'>
          <Container>

          <Link to='/' className='navbar-brand col-2'>
              <img className="hidden-logo" src={logo_san} alt="Συσκευασμένα σάντουιτς" />
              <img className="main-logo" src={logo} alt={data.site.siteMetadata.title} /></Link>
            <ul className='nav navbar-nav main-menu offset-md-2 col-sm-12 col-md-7'>

              {user && (
                <li className='nav-item'>
                  <Link to='/admin' className='nav-link'>CMS</Link>
                </li>
              )}

              <li className='nav-item'>
                <Link to='/' className='nav-link'>ΑΡΧΙΚΗ</Link>
              </li>

              <li className='nav-item'>
                <Link to='/χειροποιητες-μπαρες-δημητριακων' className='nav-link'>ΜΠΑΡΕΣ</Link>
              </li>

              <li className='nav-item'>
                <Link to='/συσκευασμενα-κρυα-σαντουιτς' className='nav-link'>ΣΑΝΤΟΥΙΤΣ</Link>
              </li>

              <li className='nav-item'>
                <Link to='/ta-nea-mas' className='nav-link'>ΝΕΑ</Link>
              </li>

              <li className='nav-item'>
                <Link to='/to-spitiko' className='nav-link'>Η ΕΤΑΙΡΕΙΑ</Link>
              </li>

              <li className='nav-item'>
                <Link to='/epikoinonia' className='nav-link'>ΕΠΙΚΟΙΝΩΝΙΑ</Link>
              </li>

              <li className='nav-item'>
                <Link to='/about-us' className='nav-link'>EN</Link>
              </li>

            </ul>
            <div className="after-main-menu col-4">
              <div className="rightA rightColumn col-6">
              <a href='https://spitiko-sandwich.com' className='productCat'><img className="catLogo" src={logo_san} alt="Συσκευασμένα σάντουιτς" /></a>
              </div>
              <div className="rightB rightColumn col-6">
                <Link to='/χειροποιητες-μπαρες-δημητριακων' className='productCat'><img className="catLogo" src={logo} alt="Μπάρες δημητριακών" /></Link>
              </div>
            </div>
          </Container>
        </div>
        <div class="container contentArea">
          <div class="row">
            <div className='pageContent col-lg-9'>{children}</div>
            <Sidebar />
          </div>
        </div>
        <Footer />
      </div>
    )} />
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.func
}

const pageQuery = graphql`
  query LayoutIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default TemplateWrapper
