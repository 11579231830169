import React from 'react'
import Link from 'gatsby-link'

import vendor from "../../assets/images/vendor.png"
import featuredPost from "../../assets/images/gt19_logo.png"
import spitiko from '../../assets/images/spitiko.png'
import iso from '../../assets/images/iso_v.png'
import mpares from '../../assets/images/logo.png'
import santouits from '../../assets/images/logo_san.png'

import { FaFacebook, FaInstagram, FaArrowRight } from 'react-icons/fa';

const Sidebar = (props) => (
  <div className="sidebar col-lg-3">
    <Link to="/epikoinonia" className="productCat">
      <img className="" src={vendor} alt="Θέλω να γίνω συνεργάτης" />
    </Link>
    <div className="featuredPost p-3">
      <Link to="/ta-nea-mas" className="productCat text-white">
        <h5>
          ΤΑ ΝΕΑ ΜΑΣ <FaArrowRight />
        </h5>
        <img
          className="whiteBGFivePadding"
          src={featuredPost}
          alt="Food Expo 2019"
        />
      </Link>
      <div className="postMeta">
        <span className="tags small">Great Taste Award 2019</span>
        <span className="postDate small">United Kingdom</span>
      </div>
    </div>
    <div className="getinTouch">
      <div className="sideContact">
        <Link to="/epikoinonia" className="productCat text-white">
          <h5 className="text-white">
            ΕΠΙΚΟΙΝΩΝΙΑ <FaArrowRight />
          </h5>
        </Link>
        <span className="contactInfo text-white">
          Διεύθυνση: Xρυστοστόμου Σμύρνης 17, TK56429, Eυκαρπία Θεσσαλονίκης.
          Τηλ: 2310686744
          <br />
          Email: info@mpares.com
        </span>
      </div>
      <Link to="/to-spitiko">
        <img
          src={spitiko}
          className="fullImage logos"
          alt="Το Σπιτικό Παπακωνσταντίνου"
        ></img>
      </Link>
      <img
        src={iso}
        className="fullImage logos"
        alt="Το Σπιτικό Παπακωνσταντίνου"
      ></img>
      <Link to="/χειροποιητες-μπαρες-δημητριακων">
        <img
          src={mpares}
          className="fullImage logos"
          alt="Μπάρες δημητριακών Το Σπιτικό Παπακωνσταντίνου"
        ></img>
      </Link>
      <a href="https://spitiko-sandwich.com">
        <img
          src={santouits}
          className="fullImage logos"
          alt="Συσκευασμένα κρύα σάντουιτς Το Σπιτικό Παπακωνσταντίνου"
        ></img>
      </a>
      <div className="getinTouchSocial">
        <span class="socialText">Βρείτε μας σε</span>
        <ul className="copyright social">
          <li className="sm-icon">
            <a
              href="https://www.instagram.com/spitikopapakonstantinou/"
              className="icon"
            >
              <span className="label text-white">
                <FaInstagram />
              </span>
            </a>
          </li>
          <li className="sm-icon">
            <a
              href="https://www.facebook.com/mpares.spitikopapakonstantinou"
              className="icon"
            >
              <span className="label text-white">
                <FaFacebook />
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Sidebar